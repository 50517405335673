export const rootName = "";
// export const DOMAIN = "sandbox5e91d20fdb0645c6a127974941745745.mailgun.org";
// export const API_KEY = "b8f8b2cd8455aa391cc685853beda3c7-e31dc3cc-bf5d18a2";
// export const DOMAIN = process.env.MAILGUN_DOMAIN;
// export const API_KEY = process.env.MAILGUN_API_KEY;

export const DOMAIN = "bithreum.org";
export const API_KEY = "f4769953b9e5049338dce45a40a53868-24e2ac64-0d3140d2";

export const SEVICE_ID ="service_tcu9cvh";
export const TEMPLATE_ID ="template_07csesh";
export const USER_ID="user_AEbEpsY6J8BemvILCrXwd";