import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { rootName } from "../constant";
import Login from "../pages/AdminPages/Login/Login";
import PublicSale from "../pages/AdminPages/PublicSale/PublicSale";

const Adminroutes = () => {
  return (
    <>
      <BrowserRouter>
        <Route
          path={`${rootName}/admin/login`}
          component={Login}
          exact={true}
        />
        <Route
          path={`${rootName}admin/publicsale`}
          component={Adminroutes}
          exact={true}
        />
        <Route
          path={`${rootName}/admin/publicsale`}
          component={PublicSale}
          exact={true}
        />
      </BrowserRouter>
    </>
  );
};

export default Adminroutes;
