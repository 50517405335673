import React, { useState } from "react";
import ReactDOM, { Link } from "react-dom";
// import { Formik, Form, Field } from "formik";
import "./contact.scss";
import Button from "../Button/Button";
import Heading from "../Heading/Heading";
import * as Yup from "yup";
import { API_KEY, DOMAIN, SEVICE_ID, TEMPLATE_ID, USER_ID } from "../../constant";
// import { Col, Row, ModalContainer, Modal, Container } from "react-bootstrap";
import { Form, Col, Container, ModalContainer, Row, FloatingLabel, Modal } from "react-bootstrap";
import emailjs from "emailjs-com";

import Connect from "../Connect/connect";

const mailgun = require("mailgun-js");

const SignupSchema = Yup.object().shape({

  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Name Required ")
    .trim("The name cannot include leading and trailing spaces")
    .strict(true),
  email: Yup.string()
    .email("Invalid email")
    .required("Email Required")
    .trim("The email cannot include leading and trailing spaces")
    .strict(true),
  subject: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Subject Required")
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true),
  message: Yup.string()
    .min(2, "Too Short!")
    .max(300, "Too Long!")
    .required("Message Required")
    .trim("The message cannot include leading and trailing spaces")
    .strict(true),
});

const Contact = (props) => {
  const [buttondisable, setButtondisable] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [inputData, setInputData] = useState(
    { name: '', email: '', subject: '', message: '' }
  );

  const handleclick = () => {
    setTimeout(() => {
      setButtondisable(true);
    }, 500);
    setTimeout(() => {
      setButtondisable(false);
    }, 2000);
  };

  const submitData = (e) => {
    e.preventDefault();

    emailjs.sendForm(SEVICE_ID, TEMPLATE_ID, e.target,USER_ID)
      .then((result) => {
        setShow(true);

        e.target.reset();
        this.setState({ name: '', email: '', subject: '', message: '' });
      }, (error) => {
        console.log(error.text);
      });
  }
  return (
    <>
      <div className="contactWork" id="contactus">
        <Container>
          <Row className="g-4">
            <Col xs={12} md={12} lg={12} xl={12} className="mt-3">
              <div className="textContact">
                <h2 className="headingGraphic mission text-center">
                  <Heading className="roadmap-heading" heading="  Contact Us" />
                  Contact Us
                </h2>
                <p className="text-center pb-5 text-white ">
                  If you have any question, we are happy to help!
                </p>


                <Form onSubmit={(e) => submitData(e)}>
                  <Row className="mb-3">
                    <Form.Group as={Col} sm={12} md={6} lg={6} xl={6} controlId="formGridEmail" className="name_con">
                      <Form.Control type="text"
                        placeholder="Enter Name*"
                        name="name"
                        onChange={(e) => {
                          setInputData((prevState) => ({ ...prevState, name: e.target.value }));
                        }}


                        required />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} md={6} lg={6} xl={6} controlId="formGridPassword">
                      <Form.Control
                        type="email"
                        placeholder="Enter Email*"
                        className="pswrd_con"
                        name="email"
                        onChange={(e) => {
                          setInputData((prevState) => ({ ...prevState, email: e.target.value }));
                        }}

                        required
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Control type="text"
                      placeholder="Subject*"
                      name="subject"
                      onChange={(e) => {
                        setInputData((prevState) => ({ ...prevState, subject: e.target.value }));
                      }}

                      required />
                  </Form.Group>

                  <FloatingLabel controlId="floatingTextarea2" label="Message">
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "188px" }}
                      name="message"
                      onChange={(e) => {
                        setInputData((prevState) => ({ ...prevState, message: e.target.value }));
                      }}

                      required
                    />
                  </FloatingLabel>

                  <Button
                    title="Send Message"
                    className="contact_btn"
                  />
                </Form>

              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Connect
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        contactus={true}
      />
    </>
  );
};

export default Contact;
