import React from "react";
import { useState } from "react";
import Button from "../Button/Button";
import "./AdminHeader.scss";
import Connect from "../Connect/connect";

const AdminHeader = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [header, setHeader] = useState("header");

  return (
    <>
      <div className="admin_header text-end">
        <Button className="header_btn" title="Address" />
        <Button className="header_btn" title="Address" />
        <Button
          className="header_btn"
          title="Disconnect Wallet"
          onClick={() => handleShow(true)}
          to="/"
        />
        <Connect
          show={show}
          handleShow={handleShow}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default AdminHeader;
