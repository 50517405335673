import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./LandingFooter.scss";
import Logo from "../../assets/media/logo.png";
import twit from "../../assets/images/twit.svg";
import reddit from "../../assets/media/reddit.png";
import linkdin from "../../assets/images/linkdin.svg";
import { Link, animateScroll as scroll } from "react-scroll";

const LandingFooter = (props) => {
  return (
    <div className="landingFooterWrap">
      <Container className="custom-container">
        <Row>
          <Col xs={12} md={12} lg={6} xl={6}>
            <img src={Logo} className="mobileLogo" />
            <p className="logoSubHeading">
              SHIBABITCOIN - Beautiful conjugation of two state-of-
              <br />
              the-art blockchain facilities proffering Immutability and
              <br /> High returns on your digital assets.
            </p>
          </Col>
          <Col xs={12} md={12} lg={3} xl={3}>
            <div className="quickLink">
              <h6 className="labelHeading">Quick Links</h6>
              <div className="footer-links">
                <ul>
                  <li>
                    <Link
                      className="headerLinks nav-link"
                      activeClass="active"
                      to="home"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={300}
                    >
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="headerLinks nav-link"
                      activeClass="active"
                      to="usandOurMission"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={300}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="headerLinks nav-link"
                      activeClass="active"
                      to="keyFeatures"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={300}
                    >
                      Roadmap
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="headerLinks nav-link"
                      activeClass="active"
                      to="keyFeatures"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={300}
                    >
                      Team
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={3} xl={3}>
            <div className="quickLink">
              <h6 className="labelHeading">Product</h6>
              <ul>
                <li>
                  <Link
                    className="headerLinks nav-link"
                    activeClass="active"
                    to="tokenomics"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                  >
                    Terms & Condition
                  </Link>
                </li>
                <li>
                  <Link
                    className="headerLinks nav-link"
                    activeClass="active"
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                  >
                    Help & Faq
                  </Link>
                </li>
                <li>
                  <Link
                    className="headerLinks nav-link"
                    activeClass="active"
                    to="roadMap"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={300}
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="miniFooter">
        <p>
          Copyright © 2022 Shibabitcoin | All rights reserved. <span>|</span>
        </p>
        <ul>
          {/* <li>
            <a href="javascript:void(0)">
              <img src={fbIcon} />
            </a>
          </li> */}
          <li>
            <a href="https://twitter.com/ShibabitC" target="_blank">
              <img src={twit} />
            </a>
          </li>
          {/* <li>
            <a href="javascript:void(0)">
              <img src={insta} />
            </a>
          </li> */}
          <li>
            <a href="https://www.reddit.com/user/SHIBTC" target="_blank">
              <img src={reddit} />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F77041568%2Fadmin%2F"
              target="_blank"
            >
              <img src={linkdin} />
            </a>
          </li>
          {/* <li>
            <a href="javascript:void(0)">
              <img src={youtube} />
            </a>
          </li> */}
          {/* <li>
            <a href="javascript:void(0)">
              <img src={tely} />
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default LandingFooter;
