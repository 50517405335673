import React from 'react'
import './Heading.scss'
function Heading(props) {
    return (
        <>
            <h1 className={`heading-text ${props.className}`}> {props.heading}</h1>
        </>
    )
}

export default Heading
