import React from "react";
import "./Login.scss";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div class="login_sec">
      <div className="login_box">
        <p>09038948984759843598307139</p>
        <div className="type_text d-flex align-items-center justify-content-space-between">
          <Button className="login_btn" title="Login"/>
          <Link to="/">Go to Crowd Funding </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
