import React from "react";
import { Route, Switch } from "react-router-dom";
import LandingPage from "../../pages/LandingPage/LandingPage";
const PublicRoutes = () => {
  return (
    <div className="publicLayout">
      <Switch>
        <Route path={"/"} component={LandingPage} exact={true} />
      </Switch>
    </div>
  );
};
export default PublicRoutes;
