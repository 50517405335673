import React from "react";
import { Row, Col } from "react-bootstrap";
import "./AdminSidebar.scss";
import logo from "../../assets/images/head_logo.png";
import icon from "../../assets/images/home.svg";
import icon1 from "../../assets/images/settings.svg";
import { Link } from "react-router-dom";

const AdminSidebar = () => {
  return (
    <>
      <Col md={0} lg={2} xl={2} className="sidebar_sec">
        <img src={logo} alt="" className="side_logo mb-5" />
        <ul>
          <li>
            <img src={icon} alt="" className="mr-2" />
            <Link to="/">Public Sale</Link>
          </li>
          <li>
            <img src={icon1} alt="" className="set mr-2" />
            <Link to="/">Settings</Link>
          </li>
        </ul>
      </Col>
    </>
  );
};

export default AdminSidebar;
