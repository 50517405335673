import React, { useState } from "react";
import "./LandingPage.scss";
import ScrollAnimation from "react-animate-on-scroll";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import iconRight from "../../assets/media/bannerimg.png";
import welcomes from "../../assets/media/welcome.png";
import Contact from "../../components/Contact/Contact";
import LandingFooter from "../../components/LandingFooter/LandingFooter";
import Heading from "../../components/Heading/Heading";
import man from "../../assets/media/man.png";
import contracticon from "../../assets/media/contracticon.svg";
import nfticon from "../../assets/media/nft.svg";
import anonymous from "../../assets/media/anonymous.svg";
import storage from "../../assets/media/storage.svg";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

const LandingPage = () => {
 
  const [showModal, setShowModal] = useState(false);
  let history = useHistory();

  const [button] = useState(true);

  const modalShowHandler = () => setShowModal(true);
  const hideModal = () => setShowModal(false);
  return (
    <div className="mainDiv">
      <div className="first_sec">
        <Header showModalMeta={() => modalShowHandler()} />
        <ScrollAnimation animateIn="fadeIn" duration="3">
          <div className="landingMainBlock" id="home">
            <Container className="custom-container">
              <Row>
                <Col lg={6} md={12} className="textImageBlocks">
                  <div className="textBlock">
                    <h1 className="headingText">
                      SHIBABITCOIN - Beautiful conjugation of two
                      state-of-the-art blockchain facilities proffering
                      Immutability and High returns on your digital assets.
                    </h1>
                    <p className="textpara text-white fw-lighter">
                      Immortalize your digital assets in the blockchain
                      leveraging the stunning SHIBABITCOIN’s NFT marketplace
                      along with pocketing huge profits on your investments
                      utilizing SHIBABITCOIN’s DEX.
                    </p>
                    <Button
                      title="Whitepaper"
                      target="_blank"
                      onClick={() => {
                        window.open(
                          "https://shibabitcoin.s2.staging-host.com/pdf/shibtc_whitepaper.pdf"
                        );
                      }}
                    ></Button>
                    <Button
                      className="outline-btn"
                      title="Trade on Probit Global"
                      target="_blank"
                      onClick={() => {
                        window.open(
                          "https://www.probit.com/app/exchange/SHIBTC-USDT"
                        );
                      }}
                    ></Button>
                  </div>
                </Col>
                <Col lg={6} md={12} className="textImageBlocks banner-img">
                  <div className="imageBlock">
                    <img src={iconRight} className="handLogo" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ScrollAnimation>
      </div>
      <div className="protocolWork">
        <Container className="custom-container">
          <Row className="g-4 " id="aboutus">
            <Col
              xs={12}
              md={12}
              lg={5}
              xl={5}
              className="mt-0 protocolImageBlock"
            >
              <ScrollAnimation animateIn="fadeInRight" duration={1}>
                <img src={welcomes} alt="icon" className="welcome-img" />
              </ScrollAnimation>
            </Col>
            <Col xs={12} md={12} lg={7} xl={7} className="mt-3">
              <div className="textProtocol">
                <ScrollAnimation animateIn="fadeInLeft" duration={0.5}>
                  <h2 className="headingGraphic mission">
                    Bonjour, Mesmerizing world of <br />
                    SHIBABITCOIN Welcomes you
                    <Heading heading="Welcomes" />
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={0.5}
                  delay={100}
                >
                  <p>
                    SHIBABITCOIN, exercising low risk and low management
                    hierarchy with the prime goal to harvest the golden crypto
                    investments to reap the exuberating profits, deployed their
                    native ERC-20 token named “SHIBTC” on the Ethereum
                    blockchain promising the users and potential consumers an
                    out-of-this-world experience.
                  </p>
                  <p>
                    SHIBABIITCOIN is stepping up the game in the decentral world
                    by introducing not one but two products back to back,
                    SHIBABITCOIN NFT market platform and Decentral Exchange
                    where its NFT market platform will spotlight the
                    Sovereignty, Scalability, and Adaptability of NFTs, and the
                    decentralized trading platform will bestow a seamless and
                    frictionless experience to its community and make it a
                    viable platform for the crypto enthusiasts
                  </p>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={0.5}
                  delay={500}
                >
                  <Button
                    title="Whitepaper"
                    target="_blank"
                    onClick={() => {
                      window.open(
                        "https://shibabitcoin.s2.staging-host.com/pdf/shibtc_whitepaper.pdf"
                      );
                    }}
                  />
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
          <Row className="token" id="tokenomics">
            <Col xs={12} md={12} lg={7} xl={7} className="mt-3 ">
              <div className="textProtocol">
                <ScrollAnimation animateIn="fadeInLeft" duration={0.5}>
                  <h2 className="headingGraphic mission">
                    SHIBTC - The Most Reliable <br />
                    Token Is Here
                    <Heading heading="SHIBTC" />
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={0.5}
                  delay={100}
                >
                  <p>
                    SHIBTC is an ERC-20 standard native token of SHIBABITCOIN
                    harnessing the Ethereum blockchain up to its maximum extent.
                    The DeFi utilities it can take part in will make the
                    investors drool. SHIBTC can be Staked in liquidity pools on
                    any exchanges to avail of the reward tokens which can
                    further be staked to start a yield farming cycle.
                  </p>
                  <p>
                    SHIBTC will primarily be armed with high-speed transactions
                    and flawless trading. Trading for the SHIBTC can be
                    commenced as soon as the token launches as it will have the
                    initial liquidity packed. The ERC 20-based SHIBTC coin
                    usefulness spans use-cases and is incredibly future-proof
                    thanks to its remarkable interoperability.
                  </p>
                  <p>
                    {" "}
                    “ SHIBABITCOIN covers all the corners which were previously
                    cut by fellow projects”
                  </p>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInLeft"
                  duration={0.5}
                  delay={500}
                >
                  <Button
                    className="outline-btn ms-0"
                    title="Trade on Probit Global"
                    target="_blank"
                    onClick={() => {
                      window.open(
                        "https://www.probit.com/app/exchange/SHIBTC-USDT"
                      );
                    }}
                  ></Button>
                </ScrollAnimation>
              </div>
            </Col>

            <Col
              xs={12}
              md={12}
              lg={5}
              xl={5}
              className="mt-0 protocolImageBlock"
            >
              <ScrollAnimation
                animateIn="fadeInRight"
                duration={1}
                className="Text-Right"
              >
                <img src={man} alt="icon" className="man-img" />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="features" id="feature">
        <Container className="custom-container">
          <Row className="g-4 align-items-center">
            <Col md={12} lg={7}>
              <div className="feature-left">
                <div className="feature-box">
                  <img src={contracticon} alt="icon" />
                  <h4>Integrated Smart Contracts</h4>
                  <p>
                    Pi finance token will play a very powerful role within our
                    ecosystem services like pi finance exchanger, pi finance
                    blockchain and pi finance multi wallet PFT will be used as
                    the main currency to power our ecosystem services.
                  </p>
                </div>
                <div className="feature-box feature-down">
                  <img src={nfticon} alt="icon" />
                  <h4>Captivating UI</h4>
                  <p>
                    The interface used for the NFT marketplace will take the
                    users to another world and will be simple to navigate and
                    utilize that even a total novice to the blockchain can
                    leverage.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} lg={5}>
              <div className="feature-txt">
                <p>
                  SHIBABITCOIN Covers All The Corners Which Were Previously Cut
                  By Fellow Projects
                </p>
                <p> SHIBABITCOIN FEATURES</p>
                <Button
                  title="View Smart Contract"
                  target="_blank"
                  onClick={() => {
                    window.open(
                      "https://etherscan.io/address/0x5D303E548728C8C472Fa23955aFD2D6301dB427D"
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="features feature-exchange" id="tokenomics">
        <Container className="custom-container">
          <Row className="g-4 align-items-center">
            <Col lg={5} md={12}>
              <div className="feature-txt">
                <p>
                  SHIBABITCOIN DECENTRAL <br /> EXCHANGE
                </p>
                <Button
                  title="View Smart Contract"
                  target="_blank"
                  onClick={() => {
                    window.open(
                      "https://etherscan.io/address/0x5D303E548728C8C472Fa23955aFD2D6301dB427D"
                    );
                  }}
                />
              </div>
            </Col>
            <Col lg={7} md={12}>
              <div className="feature-left justify-content-end">
                <div className="feature-box anonumous-feature">
                  <img src={anonymous} alt="icon" />
                  <h4>Anonymous</h4>
                  <p>
                    Due to the decentral nature of the trading platform, the
                    privacy of the users and consumers will be kept anonymous to
                    the world and risk factors regarding asset or identity theft
                    drops down to nil.2.
                  </p>
                </div>
                <div className="feature-box ">
                  <img src={storage} alt="icon" />
                  <h4>Immutability of Storage</h4>
                  <p>
                    Engraving the records of transactions in every node of the
                    blockchain network and acting Immutability factor regarding
                    blocks will become a supporting column in the SHIBABIITCOIN
                    empire.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="protocolWork custom-space" id="roadMap">
        <Container className="custom-container">
          <div className="text-center">
            <ScrollAnimation animateIn="fadeInDown" duration={0.5}>
              <h2 className="headingGraphic mission text-center">
                Roadmap
                <Heading className="roadmap-heading" heading=" Roadmap" />
              </h2>
            </ScrollAnimation>
          </div>
          <div className="roadMapWrap">
            <div className="roadmapList yearLeft">
              <div className="yearText">
                <h5>Phase 1</h5>
              </div>
              <div className="listView">
                <p>
                  Market research verification to technical feasibility
                  verification of market feasibility token deployment
                </p>
              </div>
            </div>
            <div className="roadmapList yearRight">
              <div className="listView">
                <p>
                  Constitute the project team development of centralized crypto
                  exchange platform creation of whitepaper
                </p>
              </div>
              <div className="yearText">
                <h5>Phase 2</h5>
              </div>
            </div>
            <div className="roadmapList yearLeft">
              <div className="yearText">
                <h5>Phase 3</h5>
              </div>
              <div className="listView">
                <p>
                  Seed round private sale building community through campaigns{" "}
                </p>
              </div>
            </div>
            <div className="roadmapList yearRight">
              <div className="listView">
                <p>Public sale airdrops listing</p>
              </div>
              <div className="yearText">
                <h5>Phase 4</h5>
              </div>
            </div>
            <div className="roadmapList yearLeft">
              <div className="yearText">
                <h5>Phase 5</h5>
              </div>
              <div className="listView">
                <p>
                  Development of our centralized exchange platform launch of the
                  centralized exchange platform deployment in android & IOS
                  platform
                </p>
              </div>
            </div>
            <div className="roadmapList yearRight">
              <div className="listView">
                <p>
                  NFT marketplace development implementation of fractional and
                  redeemable NFTs development of decentralised exchange platform
                </p>
              </div>
              <div className="yearText">
                <h5>Phase 6</h5>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="sectionWraps faq protocolWork" id="faq">
        <Container className="custom-container">
          <div className="headingWrap text-center">
            <ScrollAnimation animateIn="fadeInDown" duration={0.5}>
              <h2 className="headingGraphic smallGraphic text-center">
                FAQ's
                <Heading className="roadmap-heading" heading="  FAQ's" />
              </h2>
            </ScrollAnimation>
          </div>
          <Row className="align-items-end">
            <Col md={12} lg={6} xl={6} className="acc_img">
              <img
                src={require("../../assets/media/faq.svg").default}
                className="img-fluid"
                alt="icon"
              />
            </Col>
            <Col md={12} lg={6} xl={6}>
              <Accordion className="faq-acc">
                <h2 className="faq-heading">
                  Frequently Asked <span className="red">Questions</span>
                </h2>
                <Accordion.Item eventKey="0">
                  <div className="faq-body">
                    <Accordion.Header>What is SHIBABITCOIN?</Accordion.Header>
                    <Accordion.Body>
                      SHIBABITCOIN is an integrated ecosystem of decentral
                      applications that intends to take the blockchain by storm
                      with the creation and implementation of its own
                      cryptocurrency exchange and NFT marketplace platform.
                    </Accordion.Body>
                  </div>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <div className="faq-body">
                    <Accordion.Header>
                      What does SHIBABITCOIN's NFT Marketplace harbors?
                    </Accordion.Header>
                    <Accordion.Body>
                      Freedom to artists, Rightful royalty clause,
                      multi-currency buy, easy integration of crypto wallet. You
                      name it, SHIBABITCOIN has it.
                    </Accordion.Body>
                  </div>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <div className="faq-body">
                    <Accordion.Header>
                      Why invest in SHIBABITCOIN?
                    </Accordion.Header>
                    <Accordion.Body>
                      SHIBTC, the native token of SHIBABITCOIN has a promising
                      future while complementing the Staking process churning
                      out big profits all while initiating the yield cycle.
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <div className="faq-body">
                    <Accordion.Header>
                      What type of products SHIBABITCOIN is launching?
                    </Accordion.Header>
                    <Accordion.Body>
                      SHIBABITCOIN will be developing and deploying a
                      combination of NFT marketplace and decentral exchange in
                      the decentral universe.
                    </Accordion.Body>
                  </div>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <div className="faq-body">
                    <Accordion.Header>
                      Any special case about the SHIBABITCOIN's decental
                      exchange?
                    </Accordion.Header>
                    <Accordion.Body>
                      SHIBABITCOIN’s anonymity and top-of-the-class
                      non-custodial platform will assign the handling of assets
                      back to its rightful owner.
                    </Accordion.Body>
                  </div>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
      <ScrollAnimation animateIn="fadeIn" duration={0.9}>
        <Contact />
        {/* <Testform/> */}
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn" duration={0.9}>
        <LandingFooter />
      </ScrollAnimation>
    </div>
  );
};
export default LandingPage;
