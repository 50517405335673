import React from "react";
import { Container, Row } from "react-bootstrap";
import AdminHeader from "../../../components/AdminHeader/AdminHeader";
import AdminSection from "../../../components/AdminSection/AdminSection";
import AdminSidebar from "../../../components/AdminSidebar/AdminSidebar";

const PublicSale = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <AdminSidebar />
          <AdminSection />
        </Row>
      </Container>
    </>
  );
};

export default PublicSale;
