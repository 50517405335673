import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Col, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Metamask from "../../assets/images/metamask_icon.png";
import Walleticon from "../../assets/images/wallet_icon.svg";
import Trustwallet from "../../assets/images/trust-wallet.png";
import Binanceicon from "../../assets/images/Binance-Icon.png";
import "./connect.scss";
// import * as Session from "../../utils/session";
// import { connectWithWallet } from "../../redux/action/connect.action";

const Connect = (props) => {
  // const dispatch = useDispatch();

  /**
   * connect with a wallet based on type
   */

  // const connectToWallet = async (e, type) => {
  //   e.preventDefault();
  //   let Type = type.toUpperCase();
  //   Session.setLoginSession(Type);

  //   let result = await dispatch(connectWithWallet(Type));
  //   if (result) {
  //     props.handleClose(false);
  //     window.location.reload();
  //   }
  // };
  // useEffect(() => {});

  return (
    <Modal
      scrollable={true}
      className="connect_modal"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      {props?.contactus ? (<>
        <Modal.Header closeButton className="contact-success">
          <Row>
            <Col className="baseToken_style ">
              <h2>Thanks for contacting us!
              </h2>
              <p>We will get in touch with you shortly.</p>
            </Col>
          </Row>
        </Modal.Header>
      </>) : (<>
        <Modal.Header closeButton>
          <Modal.Title>Connect to a Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="baseToken_style">
              <ul>
                <li>
                  <Link>
                    MetaMask{" "}
                    <span>
                      <img src={Metamask} alt="Metamask" />
                    </span>{" "}
                  </Link>
                </li>
                <li>
                  <Link>
                    WalletConnect
                    <span>
                      <img src={Walleticon} alt="Wallet Icon" />
                    </span>{" "}
                  </Link>
                </li>
                <li>
                  <Link>
                    Trust Wallet{" "}
                    <span>
                      <img src={Trustwallet} alt="Trust wallet" />
                    </span>{" "}
                  </Link>
                </li>
                <li>
                  <Link>
                    Binance{" "}
                    <span>
                      <img src={Binanceicon} alt="Binance Icon" />
                    </span>{" "}
                  </Link>
                </li>
              </ul>
              <div class="add_new text-center">
                <span>New to Ethereum? &nbsp;</span>
                <a
                  href="https://ethereum.org/wallets/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about wallets
                </a>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </>)}


    </Modal>
  );
};

export default Connect;
