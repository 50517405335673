import React from "react";
import { InputGroup, FormControl, Col } from "react-bootstrap";
import "./AdminSection.scss";
import Button from "../Button/Button";
import quesmark from "../../assets/images/QuestionMarkImg.svg";
import AdminHeader from "../AdminHeader/AdminHeader";

const AdminSection = () => {
  return (
    <>
      <Col md={12} lg={10} xl={10} className="admin_sec">
        <AdminHeader />
        <div className="admin_info d-flex align-items-center">
          <img src={quesmark} alt="" /> <p>Receiver Address</p>
          <InputGroup>
            <FormControl
              placeholder="Username"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <Button title="Update" />
        </div>
        <div className="admin_info d-flex align-items-center">
          <img src={quesmark} alt="" /> <p>Receiver Address</p>
          <InputGroup>
            <FormControl
              placeholder="Username"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <Button title="Update" />
        </div>
      </Col>
    </>
  );
};

export default AdminSection;
