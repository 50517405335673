import React, { useState, useEffect } from "react";
import "./Header.scss";
import Logo from "../../assets/media/logo.png";
import logo_sign from "../../assets/media/mob-logo.png";
import MobileLogo from "../../assets/images/mobileLogo.svg";
import Metamask from "../../assets/images/metaMask.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import Connect from "../Connect/connect";
import Button from "../Button/Button";

const Header = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <Navbar sticky="top" expand="xl" className="classOnScroll" id={header}>
      <Container className="mainHeaderLending custom-container">
        <Navbar.Brand href="#">
          <div className="hamburg">
            <img src={Logo} className="handLogo" />
            <img src={logo_sign} className="logo_sign" />
            {/* <img src={MobileLogo} className="handLogo mobileLogo" /> */}
          </div>
        </Navbar.Brand>
        <div className="d-flex headerButtonWrap">
          <Navbar.Toggle aria-controls="navbarScroll" className="btn  " />

          <Navbar.Collapse id="navbarScroll" className="mobileCustom">
            <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
              <Link
                className="headerLinks nav-link"
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-115}
                duration={300}
              >
                IEO
              </Link>
              <Link
                className="headerLinks nav-link"
                activeClass="active"
                to="aboutus"
                spy={true}
                smooth={true}
                offset={-115}
                duration={300}
              >
                About
              </Link>

              <Link
                className="headerLinks nav-link"
                activeClass="active"
                to="tokenomics"
                spy={true}
                smooth={true}
                offset={-115}
                duration={300}
              >
                Why Choose Us
              </Link>

              <Link
                className="headerLinks nav-link"
                activeClass="active"
                to="feature"
                spy={true}
                smooth={true}
                offset={-115}
                duration={300}
              >
                Tokenomics
              </Link>

              <Link
                className="headerLinks nav-link"
                activeClass="active"
                to="roadMap"
                spy={true}
                smooth={true}
                offset={-115}
                duration={300}
              >
                Roadmap
              </Link>
              <Link
                className="headerLinks nav-link"
                activeClass="active"
                to="faq"
                spy={true}
                smooth={true}
                offset={-115}
                duration={300}
              >
                FAQ's
              </Link>
            </Nav>
          </Navbar.Collapse>
          <Button
            onClick={() => handleShow(true)}
            to="/"
            className="custom_trans fw-normal"
            title="Wallet"
          />
          <Connect
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
          />
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
