import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes/PublicRoutes";
import { rootName } from "./constant";
import Adminroutes from "./Adminroutes/Adminroutes";

const Application = () => {
    
    return (
        <Router>
            <Switch>
            <Route path={`${rootName}/`} component={PublicRoutes} exact={true}/>
             <Route path={`${rootName}/admin`} component={Adminroutes} />
               
            </Switch>
        </Router>
    )
}
export default Application